import { Link } from 'gatsby'
import React from 'react'
import { Col, Container, Navbar, Row, Nav, Button } from 'react-bootstrap'
import { getImage, ImageDataLike, GatsbyImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"

import * as styles from "../styles/xlnt-records.module.css"
import Icon from './icon'

interface AlbumProps {
    slug: string
    releaseDate: Date
    description: {
        data : {
            childMdx: {
                body: string
            }
        }
    }
    engDescription: {
        data : {
            childMdx: {
                body: string
            }
        }
    }
    title: string
    url: string
    image: {
        localFile: ImageDataLike
    }
    artist: {
        slug: string
        category: {
            slug: string
        }
        website: string
        xlntManaged: boolean
        name: string
    }
}

export default function Album({ data, lang, defaultLang } : { data: AlbumProps, lang: string, defaultLang: string}) {

    function createLink(to: string) {
        if (lang === defaultLang) {
            return `/${to}`;
        }
        else {
            return `/${lang}/${to}`
        }
    }

    const image = getImage(data.image.localFile)!
    return (
        <Row className={styles.album_wrapper}>
            <Col lg={4} className={styles.album_image_wrapper}>
                <GatsbyImage image={image} alt={data.title}/>
            </Col>
            <Col lg={8} className={styles.album_content_wrapper}>
                <h2> {data.artist.name} </h2>
                <h4> {data.title} </h4>
                <div className={styles.album_description}>
                    <MDXRenderer>{lang === "hu" ? data.description.data?.childMdx.body ?? "" :  data.engDescription.data?.childMdx.body ?? "" }</MDXRenderer>
                </div>
                {/* <div dangerouslySetInnerHTML={{__html: data.description}} className={styles.album_description}/> */}
                <div className='mt-auto ms-auto'>
                    {data.url && <a className={`btn btn-light ${styles.album_button}`} href={data.url}>{lang === "hu" ? 'Meghallgatom' : 'Listen'}</a> }
                    {data.artist.xlntManaged ? 
                        <Link className={`btn btn-light ${styles.album_button}`} to={createLink(`${data.artist.category.slug}/${data.artist.slug}`)}>{lang === "hu" ? 'Előadó oldala' : "Artist's page"}</Link>
                    : data.artist.website && <a className={`btn btn-light ${styles.album_button}`} href={data.artist.website}>{lang === "hu" ? 'Előadó oldala' : "Artist's page"}</a>}
                </div>
                
            </Col>
        </Row>
  );
}
